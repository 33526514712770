import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import 'aos/dist/aos.css';
import NewsStrip from "../components/news-strip";
import arrowSvg from "../images/arrow-circle-right-solid.svg"
import MetroFares from "../components/metro-fares";

class CC1ServicePage extends Component {

  componentDidMount(){
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="CC1" description="View route information and buy your ticket for the CC1 service operated by Tetley's Coaches." />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">CC1 service</h1>
              <p>York Road at Inglewood Drive - Corpus Christi</p>
            	<p>Corpus Christi – York Road at Hawkhill Avenue</p>
            </div>
          </div>


          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <h2>Route</h2>
                <h3 style={{ margin: '10px 0'}}>AM route</h3>
                <iframe title="CC1 AM route map" className="route-map" src="https://www.google.com/maps/d/embed?mid=1X4AfnA2h0rh2KFmAdKdhizvaZmkrN0co" width="100%" height="480"></iframe>
                <p><strong>Towards</strong>  Corpus Christi Catholic College</p>
                <p>From York Road/Inglewood Drive (Fire Station) via York Road, Ring Road, North
                Parkway, Kentmere Avenue, South Parkway, South Parkway Approach, Foundry
                Lane, Amberton Road, Oak Tree Drive, Foundry Approach, Harehills Lane to
                Harehills Lane/York Road – then non-stop to school.</p>

                <h3 style={{ margin: '10px 0'}}>PM route</h3>
                <iframe title="CC1 PM route map" className="route-map" src="https://www.google.com/maps/d/embed?mid=1-iwAdlgBJsa4sgx4tu5o-vRPvjnHHEA&ehbc=2E312F" width="100%" height="480"></iframe>
                <p><strong>From</strong> Corpus Christi Catholic College</p>
                <p>Non-Stop to Harehills Lane/York Road then all stops to Harehills Lane, Foundry
                Approach, Oak Tree Drive, Amberton Road, Foundry Lane, South Parkway
                Approach, South Parkway, Kentmere Avenue, North Parkway, York Road, Ring
                Road, York Road, York Road/Hawkhill Avenue.</p>

              </div>
              <div className="page-content__text">
                <h2>Timetable</h2>
                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">
                        Morning
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>York Road/Inglewood Drive</td>
                      <td>DEP</td>
                      <td>0740</td>
                    </tr>
                    <tr>
                      <td>North Parkway</td>
                      <td>DEP</td>
                      <td>0743</td>
                    </tr>
                    <tr>
                      <td>South Parkway</td>
                      <td>DEP</td>
                      <td>0747</td>
                    </tr>
                    <tr>
                      <td>Harehills Lane</td>
                      <td>DEP</td>
                      <td>0755</td>
                    </tr>
                    <tr>
                      <td>Corpus Christi CC</td>
                      <td>ARR</td>
                      <td>0820</td>
                    </tr>
                  </tbody>
                </table>

                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">
                        Afternoon
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Corpus Christi CC</td>
                      <td>DEP</td>
                      <td>1510</td>
                    </tr>
                    <tr>
                      <td>Harehills Lane</td>
                      <td>DEP</td>
                      <td>1520</td>
                    </tr>
                    <tr>
                      <td>South Parkway</td>
                      <td>DEP</td>
                      <td>1530</td>
                    </tr>
                    <tr>
                      <td>York Road/Hawkhill Avenue</td>
                      <td>ARR</td>
                      <td>1540</td>
                    </tr>
                  </tbody>
                </table>

                <MetroFares />
              </div>
            </div>
          </div>

        </div>

        <NewsStrip />

      </Layout>
    );
  }
}

export default CC1ServicePage
